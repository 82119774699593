@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.recipe-content-block {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }

    @include media-breakpoint-up(xl) {
        align-items: center;
    }

    .desktop-order-0 {
        @include media-breakpoint-up(lg) {
            order: 0;
        }
    }

    .mobile-order-0 {
        @include media-breakpoint-down(lg) {
            order: 0;
        }
    }

    .desktop-order-1 {
        @include media-breakpoint-up(lg) {
            order: 1;
        }
    }

    .mobile-order-1 {
        @include media-breakpoint-down(lg) {
            order: 1;
        }
    }

    .recipe-content-block-text {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        padding: $space-16 $space-16 $space-16 $space-24;

        @include media-breakpoint-up(lg) {
            padding: $space-48 $space-56;
        }

        a:not(.btn) {
            color: $color-b300;
        }

        ul {
            padding-left: $space-32;
        }
    }

    img {
        border-radius: $border-radius-8;
        height: 100%;
        width: 100%;

        @include media-breakpoint-up(xl) {
            height: 664px;
            width: 664px;
        }
    }

    &.no-image {
        justify-content: center;

        .recipe-content-block-text {
            max-width: 617px;
            padding: $space-48 $space-24;

            @include media-breakpoint-up(lg) {
                padding: $space-48 0;
            }
        }
    }
}
